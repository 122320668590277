import jwtDecode from 'jwt-decode';
import { useEffect, useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { isGlobalPage } from 'utils/isGlobalPage';
import { changeTheme, changeToDefaultTheme } from 'utils/changeTheme';
import { signingContextQuery } from 'graphql/queries';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import useCurrentUser from '../auth/useCurrentUser';

export const SIGNING_CSS_PATH_KEY = 'signingCssPath';

const checkLocationPathname = () => {
  return /(\/signing)\/?([^/]*)/.exec(window?.location?.pathname);
};

const useLoadTheme = () => {
  const [user, loading] = useCurrentUser(isGlobalPage());
  const [themeLoading, setThemeLoading] = useState(true);
  const client = useApolloClient();
  const context = useSigningGraphqlContext();

  const loadTheme = useCallback(async () => {
    const matchSigning = checkLocationPathname();
    if (matchSigning) {
      try {
        if (!matchSigning[2]) {
          client.query({ query: signingContextQuery, context }).then((res) => {
            if (!res?.data?.signingContext) return changeToDefaultTheme();
            const { theme } = res.data.signingContext;
            if (!theme) return changeToDefaultTheme();
            const { background: primaryColor, color } = theme;
            if (!primaryColor || !color) return changeToDefaultTheme();
            return changeTheme(primaryColor, color);
          });
        }
        const decodedToken = jwtDecode(matchSigning[2]);

        if (!decodedToken || !decodedToken.theme) return changeToDefaultTheme();

        const { background: primaryColor, color } = decodedToken.theme;

        if (!primaryColor || !color) return changeToDefaultTheme();

        return changeTheme(primaryColor, color);
      } catch {
        return changeToDefaultTheme();
      }
    } else if (user?.tenantInformation?.theme) {
      const { primaryColor, textPrimaryColor } = user.tenantInformation.theme;
      return changeTheme(primaryColor, textPrimaryColor);
    } else {
      return changeToDefaultTheme();
    }
  }, [user, client, context]);
  const userId = user?._id;
  useEffect(() => {
    if (loading) return;
    setThemeLoading(false);
    loadTheme().then(() => setThemeLoading(false));
  }, [loadTheme, loading, userId]);
  return [themeLoading];
};

export default useLoadTheme;
