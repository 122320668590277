import { Col, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { FeeType } from 'constants/shoppingCart';
import { calculateItemCountForBindingnessOption, formatHighlightBindingnessInfo } from 'model/ShoppingCart';
import FormattedPrice from './FormattedPrice';
import PriceVatInfo from './PriceVatInfo';
import classes from './PriceTable.module.less';

const prepareRowData = (shoppingCart, vatEnabled, translation) => {
  const dataSource = [];
  const preparedBiningnessInfoCounts = calculateItemCountForBindingnessOption(shoppingCart?.categories);
  const { showDigits, feeType } = shoppingCart;
  const isFixedMonthlyFeeType = feeType === FeeType.FIXED_MONTHLY;
  const priceAppearancePreferences = {
    vatEnabled,
    vatValue: shoppingCart?.vat?.value || 0,
    vatType: shoppingCart?.vatType,
    showDigits,
    isFixedMonthlyFeeType,
    translation,
  };

  const paymentIntervals = new Set(
    shoppingCart?.categories?.flatMap((category) => category.items.map((item) => item.paymentInterval)),
  );

  const isOneOffUsed = paymentIntervals.has('oneOff');
  const isMonthlyUsed = paymentIntervals.has('monthly');
  const isYearlyUsed = paymentIntervals.has('yearly');

  if (isOneOffUsed)
    dataSource.push(
      <DataRow
        key="oneOff"
        paymentInterval={translation(`common.Item.paymentIntervalValue.oneOff`)}
        totalPrice={shoppingCart.oneOff}
        bindingnessCounts={preparedBiningnessInfoCounts.oneOff}
        priceAppearancePreferences={priceAppearancePreferences}
        isOneOff
      />,
    );
  if ((isMonthlyUsed || isYearlyUsed) && shoppingCart?.monthlyPaymentDecision === 'yes' && !isFixedMonthlyFeeType)
    dataSource.push(
      <DataRow
        key="monthlyAdvance"
        paymentInterval={shoppingCart.monthlyPaymentTitle}
        totalPrice={shoppingCart.monthlyAdvance}
        bindingnessCounts={preparedBiningnessInfoCounts.monthlyAdvance}
        priceAppearancePreferences={priceAppearancePreferences}
      />,
    );
  if ((isMonthlyUsed || isYearlyUsed) && isFixedMonthlyFeeType) {
    const totalPrice = Calc.formatFixedValue({
      monthly: shoppingCart.monthly,
      yearly: shoppingCart.yearly,
    });
    const paymentInterval = <Trans i18nKey="signing.serviceOverviewStep.fixedMonthly" components={{ wbr: <wbr /> }} />;
    dataSource.push(
      <DataRow
        key="monthlyFixedFee"
        paymentInterval={paymentInterval}
        totalPrice={totalPrice}
        bindingnessCounts={preparedBiningnessInfoCounts.monthlyAdvance}
        priceAppearancePreferences={priceAppearancePreferences}
      />,
    );
  }
  if (isYearlyUsed && shoppingCart?.monthlyPaymentDecision !== 'yes' && !isFixedMonthlyFeeType)
    dataSource.push(
      <DataRow
        key="yearly"
        paymentInterval={translation(`common.Item.paymentIntervalValue.yearly`)}
        totalPrice={shoppingCart.yearly}
        bindingnessCounts={preparedBiningnessInfoCounts.yearly}
        priceAppearancePreferences={priceAppearancePreferences}
      />,
    );
  if (isMonthlyUsed && shoppingCart?.monthlyPaymentDecision !== 'yes' && !isFixedMonthlyFeeType)
    dataSource.push(
      <DataRow
        key="monthly"
        paymentInterval={translation(`common.Item.paymentIntervalValue.monthly`)}
        totalPrice={shoppingCart.monthly}
        bindingnessCounts={preparedBiningnessInfoCounts.monthly}
        priceAppearancePreferences={priceAppearancePreferences}
      />,
    );

  if (
    (isMonthlyUsed || isYearlyUsed) &&
    shoppingCart?.monthlyPaymentDecision === 'decideLater' &&
    !isFixedMonthlyFeeType
  )
    dataSource.push(
      <div className={cn(classes.separatedRow)} key="monthlyAdvance">
        <DataRow
          paymentInterval={shoppingCart.monthlyPaymentTitle}
          totalPrice={shoppingCart.monthlyAdvance}
          bindingnessCounts={preparedBiningnessInfoCounts.monthlyAdvance}
          priceAppearancePreferences={priceAppearancePreferences}
        />
      </div>,
    );

  return dataSource;
};

const TableHeaders = ({ translation }) => {
  return (
    <Row>
      <Col className={cn(classes.tableCell, classes.headerCell, classes.paymentIntervalColumn)}>
        {translation('signing.serviceOverviewStep.priceCard.pricingTable.interval')}
      </Col>
      <Col className={cn(classes.tableCell, classes.headerCell)} style={{ flex: '1 1 auto' }}>
        {translation('signing.serviceOverviewStep.priceCard.pricingTable.price')}
      </Col>
    </Row>
  );
};

/**
 * Formattes additional price informations about discounted price for the price table
 * @param {Boolean} discounted - Is the price discounted (contains a discount)
 * @param {Boolean} vatEnabled - Is vat enabled for the cart
 * @param {String} orginalNettoValue - Value without vat and without discount
 * @param {Number} vatValue - Relative value of vat
 * @param {Boolean} showDigits - Should the price show digits after comma
 * @returns {JSX.Element} Formatted additonal price informations about discounted price for one interval
 */
const getDiscountedInformation = (discounted, vatEnabled, orginalNettoPrice, vatValue, showDigits) => {
  if (!discounted) return null;
  const orginalPriceValue = vatEnabled ? orginalNettoPrice + orginalNettoPrice * vatValue : orginalNettoPrice;
  const values = { priceWithoutDiscount: Calc.formatCurrency(orginalPriceValue, { showDigits }) };
  const components = {
    s: <s />,
    b: <b />,
    i: <i />,
  };
  return (
    <Trans
      i18nKey="signing.serviceOverviewStep.priceCard.pricingTable.discountedInformation"
      values={values}
      components={components}
    />
  );
};

/**
 * Formattes additional price information about the vat for the price table
 * @param {Function} t - Translation function
 * @param {Boolean} vatEnabled - Is vat enabled for the cart
 * @param {String} vatType - Type of vat
 * @param {Number} vatValue - Relative value of vat
 * @param {String} vatAbsoluteValue - Absolute value of vat
 * @returns {String} Formatted additonal vat informations for one interval
 */
const getVatInformation = (t, vatEnabled, vatType, vatValue, vatAbsoluteValue) => {
  const vat = (vatValue || 0) * 100;
  const vatInformations = vatEnabled
    ? t('signing.serviceOverviewStep.priceCard.pricingTable.vatInformation', {
        vatValue: vat,
        vatType,
        vatAbsoluteValue,
      })
    : null;
  return vatInformations;
};

const DataRow = ({ paymentInterval, totalPrice, bindingnessCounts, isOneOff, priceAppearancePreferences }) => {
  const { vatEnabled, showDigits, isFixedMonthlyFeeType, translation, vatType, vatValue } = priceAppearancePreferences;
  const price = <FormattedPrice totalPrice={totalPrice} vatEnabled={vatEnabled} showDigits={showDigits} />;
  const priceDetails = formatHighlightBindingnessInfo(
    bindingnessCounts,
    translation,
    totalPrice.highlightBindingnessValues,
    showDigits,
  ).map((highlightInfo) => <span key={highlightInfo}>{highlightInfo}</span>);

  const priceDetailsLength = priceDetails.length;
  for (let i = 0; i < priceDetailsLength - 1; i += 1) {
    const andPosition = i * 2 + 1;
    priceDetails.splice(andPosition, 0, <span key={andPosition}>{` ${translation('common.and')} `}</span>);
  }

  const onActualCostPriceInfo =
    bindingnessCounts.onActualCostItems > 0 && totalPrice !== 0 ? (
      <span className={classes.onActualCostInfo}>
        {` ${translation('sharedPackage.numberOfActualCost', {
          actualCostCalculation: isFixedMonthlyFeeType && !isOneOff && priceDetails.length === 0 ? 'include' : 'plus',
          count: bindingnessCounts.onActualCostItems,
        })}`}
      </span>
    ) : null;

  const vatInformation = getVatInformation(
    translation,
    vatEnabled,
    vatType,
    vatValue,
    Calc.formatCurrency(totalPrice.discountedVAT, { showDigits }),
  );

  const discountedInformation = getDiscountedInformation(
    totalPrice.value !== totalPrice.discountedValue,
    vatEnabled,
    totalPrice.value,
    vatValue,
  );

  return (
    <Row align="bottom">
      <Col className={cn(classes.tableCell, classes.paymentIntervalCell, classes.paymentIntervalColumn)}>
        {paymentInterval}
      </Col>
      <Col className={cn(classes.tableCell, classes.priceCell)}>{price}</Col>
      <Col className={cn(classes.tableCell, classes.priceInfoCell)}>
        {discountedInformation}
        {discountedInformation && priceDetails.length > 0 ? '; ' : ''}
        {priceDetails}
        {(priceDetails.length > 0 || discountedInformation) && vatInformation ? '; ' : ''}
        {vatInformation}
        {onActualCostPriceInfo}
      </Col>
    </Row>
  );
};

/**
 * PriceTable component for service overview step of signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.shoppingCart - Shopping cart object
 * @returns {JSX.Element} table with total prices of shopping cart and price infos
 * @component
 */
const PriceTable = ({ shoppingCart }) => {
  const { t: translation } = useTranslation();

  const vatEnabled = shoppingCart?.showVat;
  const preparedRowComponents = prepareRowData(shoppingCart, vatEnabled, translation);

  return (
    <div className={classes.table}>
      <TableHeaders translation={translation} />
      {preparedRowComponents}
      <PriceVatInfo shoppingCart={shoppingCart} />
    </div>
  );
};

export default PriceTable;
