import { Form, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { memo, useEffect, useRef, useMemo, useCallback, Fragment } from 'react';
import { useFormikField } from 'hooks/common/useFormikField';
import { intersectionBy, difference } from 'lodash';
import { useParams } from 'react-router';
import { useDocumentTemplatesForShoppingCartContext } from 'contexts/DocumentTemplatesForShoppingCartContext';
import classes from './DocumentTypesSelect.module.less';

const { Option, OptGroup } = Select;

/**
 * Matches document templates formik value with available document template options to enrich document template data
 * @param {Object} inputParameters - Input parameters of the hook
 * @param {Boolean} inputParameters.isAllowHistoricalDocumentTemplates - Are historical document templates selectable
 * @param {Object[]} inputParameters.currentDocumentTemplatesOptions - Current document template select options
 * @param {Object[]} inputParameters.historicalDocumentTemplatesOptions - Historical document template select options
 */
const useFormikDocumentTemplates = ({
  isAllowHistoricalDocumentTemplates,
  currentDocumentTemplatesOptions,
  historicalDocumentTemplatesOptions,
}) => {
  const { value, onChange } = useFormikField('documentTemplates');
  const params = useParams();
  const isFilterDocumentTemplates = useRef();
  useEffect(() => {
    isFilterDocumentTemplates.current = false;
  }, [params.mode]);
  useEffect(() => {
    if (!isFilterDocumentTemplates.current && historicalDocumentTemplatesOptions && currentDocumentTemplatesOptions) {
      const allDocumentTemplates = [...historicalDocumentTemplatesOptions, ...currentDocumentTemplatesOptions];
      const newSelection = intersectionBy(value, allDocumentTemplates, (e) => (typeof e === 'object' ? e.value : e));
      onChange(newSelection);
      isFilterDocumentTemplates.current = true;
    }
  }, [
    currentDocumentTemplatesOptions,
    historicalDocumentTemplatesOptions,
    isAllowHistoricalDocumentTemplates,
    onChange,
    value,
  ]);
};

/**
 * Multi document template select component for shopping cart creation/edit (Needs a document template for shopping cart context)
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Boolean} inputParameters.isAllowHistoricalDocumentTemplates - Should historical document templates be selectable
 * @returns {JSX.Element} multi select for document templates
 * @component
 */
export const PDFTypeFormikSelect = ({ isAllowHistoricalDocumentTemplates }) => {
  const { t } = useTranslation();

  const { currentDocumentTemplatesOptions, historicalDocumentTemplatesOptions, loading } =
    useDocumentTemplatesForShoppingCartContext();

  useFormikDocumentTemplates({
    isAllowHistoricalDocumentTemplates,
    currentDocumentTemplatesOptions,
    historicalDocumentTemplatesOptions,
  });

  const { value, onChange: onChangeFormik } = useFormikField('documentTemplates');

  const options = useMemo(() => {
    const sections = [
      isAllowHistoricalDocumentTemplates ? historicalDocumentTemplatesOptions : [],
      currentDocumentTemplatesOptions,
    ].filter((documentTemplatesOptions) => documentTemplatesOptions?.length);

    const isSections = sections.length === 2;
    const Component = isSections ? OptGroup : Fragment;

    return sections.map((documentTemplatesOptions, isGlobal) => {
      const title = isSections
        ? t(`user.ShoppingCart.DocumentTypes.sections.${isGlobal ? 'global' : 'independent'}`)
        : null;

      return (
        <Component key={title} {...(isSections && { title })}>
          {documentTemplatesOptions?.map((option) => (
            <Option key={option.value} value={option.value} title={option.name}>
              {option.name}
            </Option>
          ))}
        </Component>
      );
    });
  }, [currentDocumentTemplatesOptions, historicalDocumentTemplatesOptions, isAllowHistoricalDocumentTemplates, t]);

  const onChange = useCallback(
    (newValues) => {
      const newValue = difference(newValues, value);
      if (!newValue.length) return;
      const newValueObject = JSON.parse(newValue[0]);
      onChangeFormik(
        newValues.filter(
          (v) =>
            !(
              v.includes(newValueObject._id) &&
              (newValueObject.initializationConfigDate
                ? !v.includes('"initializationConfigDate"')
                : v.includes('"initializationConfigDate"'))
            ),
        ),
      );
    },
    [onChangeFormik, value],
  );

  return (
    <Form layout="vertical">
      <FormItem
        className="hide-form-item-colon form-item-no-margin"
        name="documentTemplates"
        label={t('user.ShoppingCart.DocumentTypes.typesSelectLabel')}
      >
        <div className={classes.documentTypesSelect}>
          <Select
            onChange={onChange}
            disabled={loading}
            name="documentTemplates"
            mode="multiple"
            allowClear
            placeholder={t('user.ShoppingCart.DocumentTypes.typesSelectLabel')}
            optionFilterProp="title"
            showSearch
          >
            {options}
          </Select>
        </div>
      </FormItem>
    </Form>
  );
};

export default memo(PDFTypeFormikSelect, equal);
