import { Space } from 'antd';
import { Radio as RadioFormik } from 'formik-antd';
import { Radio } from 'components/common/Radio';
import FormItem from 'components/common/FormItem';
import { useFormikField } from 'hooks/common/useFormikField';
import AccountOwnerInput from './AccountOwnerInput';
import IbanAndBicInputGroup from './IbanAndBicInputGroup';

/**
 * Renders a form for SEPA mandate decision with options to grant or deny the mandate.
 * Displays additional fields for account details if the mandate is granted.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.disabled - Flag indicating whether the form fields should be disabled.
 * @param {Function} props.t - Translation function for localizing labels and placeholders.
 * @param {string} props.tenantName - Name of the tenant to be displayed in the form.
 * @param {boolean} props.canDeny - Flag indicating whether the option to deny the SEPA mandate is available.
 * @param {object} props.bankAccount - Infos about a prefilled bank account
 * @returns {ReactElement} A form component for SEPA mandate decision.
 */
const SepaMandateForm = ({ disabled, t, tenantName, canDeny, bankAccount }) => {
  const sepaDecision = useFormikField('sepaDecision');
  return (
    <>
      <FormItem name="sepaDecision" id="sepaDecision" style={{ marginLeft: '2px', marginBottom: '8px' }}>
        <RadioFormik.Group name="sepaDecision" disabled={disabled || !canDeny}>
          <Space direction="vertical">
            <Radio name="sepaDecision" value="yes" disabled={disabled}>
              {t('grantSepa.yes', { tenantName })}
            </Radio>
            <Radio name="sepaDecision" value="no" disabled={disabled}>
              {t('grantSepa.no', { tenantName })}
            </Radio>
          </Space>
        </RadioFormik.Group>
      </FormItem>

      {sepaDecision?.value === 'yes' && (
        <div>
          <AccountOwnerInput t={t} disabled={disabled} prefilledOwner={bankAccount?.accountOwnerName} />
          <IbanAndBicInputGroup t={t} disabled={disabled} prefilledBankAccount={bankAccount} />
        </div>
      )}
    </>
  );
};

export default SepaMandateForm;
