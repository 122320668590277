import { Alert } from 'antd';
import useWindowSize from 'hooks/common/useWindowSize';
import concatClassNames from 'utils/classNames';

import classes from './CustomInfoAlert.module.less';

/**
 * CustomInfoAlert component is an info alert modified for the signing process steps
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.message - Message for the alert
 * @param {String} inputParameters.description - Description for the alert
 * @param {React.ReactNode} inputParameters.icon - Icon for the alert
 * @param {String} inputParameters.className - Additional classname for the alert
 * @returns {JSX.Element} custom info alert component for signing process
 * @component
 */
const CustomInfoAlert = ({ message, description, icon, className }) => {
  const { width } = useWindowSize();
  return (
    <Alert
      message={message}
      description={description}
      type="info"
      className={concatClassNames(classes.alert, className)}
      showIcon={width > 576}
      icon={icon}
    />
  );
};

export default CustomInfoAlert;
