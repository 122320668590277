import apollo from 'graphql/apollo';
import { userCompanyQuery } from 'graphql/queries';
import { updateCompanyMutation } from 'graphql/mutations';

const updateCompany = async ({
  _id,
  type,
  name,
  identifier,
  street,
  houseNumber,
  cityCode,
  city,
  country,
  commercialObject,
}) =>
  apollo.mutate({
    mutation: updateCompanyMutation,
    variables: {
      _id,
      modifier: {
        type,
        name,
        identifier,
        commercialObject,
        address: {
          street,
          houseNumber,
          cityCode,
          city,
          country,
        },
      },
    },
    refetchQueries: _id ? [{ query: userCompanyQuery, variables: { _id } }] : undefined,
  });

export default updateCompany;
