import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CalculationMode } from 'constants/item';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';

const TranslationPrice = ({
  currentFeeTypeMessage,
  priceTranslation,
  customPrice,
  showCalculatedPrice,
  minPriceAdvanced,
  showDigits,
}) => {
  const { t } = useTranslation();
  const isTextDecoration =
    priceTranslation.variables.calculationMode !== CalculationMode.ON_ACTUAL_COST &&
    Number.isFinite(customPrice) &&
    (currentFeeTypeMessage || !showCalculatedPrice);
  return (
    <span className={isTextDecoration ? 'text-decoration-style-dotted' : ''}>
      <Trans
        i18nKey={`sharedPackage.${priceTranslation.code}`}
        components={{ del: <del />, br: <br /> }}
        values={{
          ...priceTranslation.variables,
          newlineOrWhitespace: '<br/>',
          paymentInterval: t(`common.Item.paymentIntervalValue.${priceTranslation.variables.paymentInterval}`, {
            defaultValue: '',
          }),
          separator: ',',
        }}
      />
      {priceTranslation.code === 'code_on_actual_cost' && priceTranslation.variables.minPrice > 0 ? (
        <>
          <br />
          {minPriceAdvanced ? (
            <Trans
              i18nKey="sharedPackage.minPriceOnActualCostAdvanced"
              values={{
                minPrice: Calc.formatCurrency(priceTranslation.variables.minPrice, {
                  showDigits,
                }),
              }}
              components={{ i: <i /> }}
            />
          ) : (
            <Trans
              i18nKey="sharedPackage.minPriceOnActualCost"
              values={{
                minPrice: Calc.formatCurrency(priceTranslation.variables.minPrice, {
                  showDigits,
                }),
              }}
              components={{ i: <i /> }}
            />
          )}
        </>
      ) : null}
    </span>
  );
};

export default memo(TranslationPrice, equal);
