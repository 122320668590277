import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useSigningContext from 'hooks/signing/useSigningContext';
import IdentityQuestionForm from './IdentityStep/IdentityQuestionForm';
import CustomInfoAlert from './CustomInfoAlert';

import classes from './IdentityStep.module.less';
import LockedModal from './IdentityStep/LockedModal';

/**
 * IdentityStep component is the step component for the identity check in the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @param {Object} inputParameters.stepState - Saved state of the step
 * @returns {JSX.Element} step for identity check in the signing process
 */
const IdentityStep = ({ submitReference, stepState }) => {
  const { signingContext } = useSigningContext();
  const { t: translation } = useTranslation('translation', { keyPrefix: 'signing.identityStep' });

  const privateOrCompany = signingContext?.isPrivatePerson ? 'private' : 'company';
  const name = `${signingContext?.firstName} ${signingContext?.lastName}`;
  const company = signingContext?.nameOfCompany || '';

  return (
    <>
      <LockedModal visible={signingContext?.isLocked} lockedBy={signingContext?.lockedBy} />
      <CustomInfoAlert
        message={translation('warning.message')}
        description={translation('warning.description', { name, company })}
        icon={<ExclamationCircleOutlined style={{ fontSize: '21px' }} />}
        className={classes.warningAlert}
      />
      <div className={classes.question}>
        <p>{translation(`question.${privateOrCompany}`, { name, company })}</p>
        <IdentityQuestionForm
          name={name}
          company={company}
          privateOrCompany={privateOrCompany}
          submitReference={submitReference}
          stepState={stepState}
        />
      </div>
    </>
  );
};

export default IdentityStep;
