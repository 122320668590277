import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import { SigningProcessShoppingCartContextProvider } from 'contexts/SigningProcessShoppingCartContext';
import useSigningContext from 'hooks/signing/useSigningContext';
import DecisionForm from './MonthlyPaymentDecision/DecisionForm';
import PriceCard from './ServiceOverviewStep/PriceCard';
import CustomInfoAlert from './CustomInfoAlert';
/**
 * MonthlyPaymentDecision component is the step component when monthly payment need to be decided
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @returns {JSX.Element} step to decide on monthly payment in the signing process
 */
const MonthlyPaymentDecisionStep = ({ submitReference }) => {
  const { t: translation } = useTranslation('translation', { keyPrefix: 'signing.monthlyPaymentDecisionStep' });
  const [decision, setDecision] = useState('decideLater');
  const { shoppingCart, refetch } = useSigningShoppingCart();
  const { signingContext } = useSigningContext();

  const stepWasDone = shoppingCart?.signatureData.isMonthlypaymentStepDone;

  return (
    <>
      <h1>{shoppingCart?.monthlyPaymentSignatureSettings.title || translation('defaultHeadline')}</h1>
      <CustomInfoAlert
        key="decision-alert"
        message=""
        description={
          stepWasDone
            ? translation('warning.monthlyPaymentStepDone')
            : shoppingCart?.monthlyPaymentSignatureSettings?.introductionText || translation('warning.description')
        }
        icon={<ExclamationCircleOutlined style={{ fontSize: '21px' }} />}
      />
      <div>
        {shoppingCart ? (
          <DecisionForm
            onError={() => {
              refetch && refetch();
            }}
            onDecisionChange={(value) => setDecision(value)}
            defaultValues={{ decision: shoppingCart?.monthlyPaymentDecision }}
            disabled={signingContext.typeOfSignee.toLowerCase() === 'tenant' || stepWasDone}
            submitReference={submitReference}
            labels={{
              yes:
                shoppingCart?.monthlyPaymentSignatureSettings?.optionPayMonthlyPaymentText ||
                translation('options.yes'),
              no: shoppingCart?.monthlyPaymentSignatureSettings?.optionPaySeparatelyText || translation('options.no'),
            }}
            shoppingCart={shoppingCart}
          />
        ) : (
          ''
        )}

        <div>
          <SigningProcessShoppingCartContextProvider
            value={{
              ...shoppingCart,
              monthlyPaymentDecision:
                decision === 'decideLater' || stepWasDone ? shoppingCart?.monthlyPaymentDecision : decision,
            }}
          >
            <PriceCard />
          </SigningProcessShoppingCartContextProvider>
        </div>
      </div>
    </>
  );
};

export default MonthlyPaymentDecisionStep;
