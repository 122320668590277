import { useState, useCallback, useMemo } from 'react';
import { ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { SigningProcessShoppingCartContextProvider } from 'contexts/SigningProcessShoppingCartContext';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useQuery, useMutation } from '@apollo/client';
import isOrderBookmarkedServicesStepDone from 'graphql/queries/signing/isOrderBookmarkedServicesStepDone Query';
import orderBookmarkedServices from 'graphql/mutations/signing/orderBookmarkedServices';
import useSigningContext from 'hooks/signing/useSigningContext';
import { regenerateDocumentsMutation } from 'graphql/mutations';
import signingShoppingCartQuery from 'graphql/queries/signing/signingShoppingCartQuery';
import PriceCard from './ServiceOverviewStep/PriceCard';
import BookmarkedItemsOverview from './OrderBookmarkedServices/BookmarkedItemsOverview';
import CustomInfoAlert from './CustomInfoAlert';

/**
 * Removes bookmarked items from the shopping cart
 * @param {object} shoppingCart - Shopping cart, which should be cleaned
 * @returns {object} cleaned shopping cart
 */
const useCleanedShoppingCart = (shoppingCart) => {
  const cleanedShoppingCart = useMemo(() => {
    if (!shoppingCart) return null;
    const categories = shoppingCart?.categories.map((category) => {
      const items = category.items.filter((item) => {
        if (!item.wasBookmarkedItem) return true;
        return false;
      });
      const ids = category.bookmarkedItems.filter((item) => item.wasSelected).map((item) => item._id);
      const itemIds = category.itemIds.filter((id) => !ids.includes(id));
      return { ...category, items, itemIds };
    });
    const newTotal = Calc.calcCartTotalFromCart(categories, parseFloat(shoppingCart.vat.value));
    return { ...shoppingCart, categories, ...newTotal };
  }, [shoppingCart]);
  return cleanedShoppingCart;
};

/**
 * OrderBookmarkedServicesStep component is the step component when you can ordere bookmarked items
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @returns {JSX.Element} step to configure sepa mandate
 */
const OrderBookmarkedServicesStep = ({ submitReference }) => {
  const { shoppingCart } = useSigningShoppingCart();
  const cleanedShoppingCart = useCleanedShoppingCart(shoppingCart);
  const [cartWithSelectedBookmarkedItems, setCartWithSelectedBookmarkedItems] = useState(shoppingCart);
  const signingGraphlQlContext = useSigningGraphqlContext();
  const { signingContext } = useSigningContext();
  const { data: isOrderBookmarkedServicesStepDoneData, loading } = useQuery(isOrderBookmarkedServicesStepDone, {
    context: signingGraphlQlContext,
    pollInterval: 1000 * 60 * 10, // 10 minutes
    fetchPolicy: 'network-only',
  });
  const [submitOrderBookmarkedServices] = useMutation(orderBookmarkedServices, {
    context: signingGraphlQlContext,
    refetchQueries: [{ query: signingShoppingCartQuery, context: signingGraphlQlContext }],
  });

  const [regenerateDocs] = useMutation(regenerateDocumentsMutation, {
    context: signingGraphlQlContext,
  });

  const isTenant = signingContext?.typeOfSignee?.toLowerCase() === 'tenant';

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    showContinueLoading: true,
    onContinue: async (isStepBeforeSigning) => {
      if (isTenant) {
        return true;
      }

      const bookmarkedItems = [];

      if (
        !cartWithSelectedBookmarkedItems &&
        (!shoppingCart?.signatureData?.doesDocumentsNeedToBeGenerated || !isStepBeforeSigning)
      ) {
        return true;
      }

      if (!cartWithSelectedBookmarkedItems && isStepBeforeSigning) {
        await regenerateDocs();
        return true;
      }

      cartWithSelectedBookmarkedItems?.categories.forEach((category) => {
        const originalCategory = cleanedShoppingCart.categories.find((c) => c._id === category._id);
        category.items.forEach((item) => {
          if (!originalCategory?.itemIds.includes(item._id)) {
            bookmarkedItems.push(item._id);
          }
        });
      });

      const alreadyOrdered = shoppingCart.categories
        .flatMap((category) => category.items)
        .filter((item) => item.wasBookmarkedItem)
        .map((item) => item._id);

      const changed =
        bookmarkedItems.some((item) => !alreadyOrdered.includes(item)) ||
        alreadyOrdered.some((item) => !bookmarkedItems.includes(item));

      try {
        if (!changed && (!shoppingCart?.signatureData?.doesDocumentsNeedToBeGenerated || !isStepBeforeSigning)) {
          return true;
        }
        if (!changed && isStepBeforeSigning) {
          await regenerateDocs();
          return true;
        }
        const response = await submitOrderBookmarkedServices({
          variables: {
            bookmarkedServiceIds: bookmarkedItems,
          },
        });
        return response?.data?.orderBookmarkedServices.success;
      } catch (e) {
        message.error(e.message, 5);
      }

      return false;
    },
  };

  const handleBookmarkedItemsSelected = useCallback(
    (categoryId, newSelectedBookmarkedItems) => {
      let updatedShoppingCart = { ...(cartWithSelectedBookmarkedItems || shoppingCart) };
      updatedShoppingCart.categories = updatedShoppingCart.categories.map((category) => {
        if (category._id !== categoryId) {
          return category;
        }
        const baseCategory = cleanedShoppingCart.categories.find((c) => c._id === categoryId);
        const newCategory = { ...category };
        newCategory.items = [...baseCategory.items, ...newSelectedBookmarkedItems];
        newCategory.itemIds = [...baseCategory.itemIds, ...newSelectedBookmarkedItems.map((item) => item._id)];
        return newCategory;
      });
      const newTotal = Calc.calcCartTotalFromCart(updatedShoppingCart.categories, parseFloat(shoppingCart.vat.value));
      updatedShoppingCart = { ...updatedShoppingCart, ...newTotal };
      setCartWithSelectedBookmarkedItems(updatedShoppingCart);
    },
    [shoppingCart, cartWithSelectedBookmarkedItems, cleanedShoppingCart],
  );

  if (!shoppingCart) {
    return null;
  }

  const disabled = loading
    ? true
    : isTenant || !!isOrderBookmarkedServicesStepDoneData?.isOrderBookmarkedServicesStepDone;

  return (
    <SigningProcessShoppingCartContextProvider value={shoppingCart}>
      {isTenant && (
        <CustomInfoAlert
          icon={<LockOutlined />}
          description={<Trans i18nKey="signing.orderBookmarkedItemsStep.warning.tenantAlert" />}
        />
      )}
      {isOrderBookmarkedServicesStepDoneData?.isOrderBookmarkedServicesStepDone && (
        <CustomInfoAlert
          description={<Trans i18nKey="signing.orderBookmarkedItemsStep.warning.orderBookmarkedItemsStepDone" />}
          icon={<ExclamationCircleOutlined style={{ fontSize: '21px' }} />}
        />
      )}
      <CustomInfoAlert
        description={<Trans i18nKey="signing.orderBookmarkedItemsStep.warning.description" />}
        icon={<ExclamationCircleOutlined style={{ fontSize: '21px' }} />}
      />
      {shoppingCart.categories.map((category) => {
        return (
          <BookmarkedItemsOverview
            disabled={disabled}
            category={category}
            onBookmarkedItemsSelected={handleBookmarkedItemsSelected}
            isTenant={isTenant}
            key={category._id}
          />
        );
      })}
      <PriceCard shoppingCartFromProps={cartWithSelectedBookmarkedItems} />
    </SigningProcessShoppingCartContextProvider>
  );
};

export default OrderBookmarkedServicesStep;
