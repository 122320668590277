import { useSubscription } from '@apollo/client';
import { Modal } from 'antd';
import { Button } from 'components/common/Button';
import waitForUnlockSigningProcessSubscription from 'graphql/subscriptions/signing/waitForUnlockSigningProcessSubscription';
import { useTranslation } from 'react-i18next';
import classes from './LockedModal.module.less';

/**
 * Modal, which informs signee about that the signing process is locked
 * Refreshes the page when the signing process is unlocked
 * or signee clicks on the reload button
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.visible - Visibility of the modal
 * @param {string} inputProperties.lockedBy - Name of the signee who locked the signing process
 * @returns {JSX.Element} Modal for the locked signing process
 * @component
 */
const LockedModal = ({ visible, lockedBy }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'signing.identityStep.lockModal' });
  return (
    <Modal
      title={t('title')}
      closable={false}
      visible={visible}
      className={classes.medium_modal}
      footer={
        <Button onClick={() => window.location.reload()} type="primary">
          {t('button')}
        </Button>
      }
    >
      <ModalContent lockedBy={lockedBy} />
    </Modal>
  );
};

/**
 * Content for the lock modal with unlock subscription
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.lockedBy - Name of the signee who locked the signing process
 * @returns {JSX.Element} Content for the lock modal
 * @component
 */
const ModalContent = ({ lockedBy }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'signing.identityStep.lockModal' });
  useSubscription(waitForUnlockSigningProcessSubscription, {
    onSubscriptionData: () => {
      window.location.reload();
    },
  });

  return (
    <div>
      <p>{t('message', { lockedBy })}</p>
    </div>
  );
};

export default LockedModal;
