import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { Alert, Skeleton, Tabs, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { useCachedQuery } from 'graphql/utils';
import { userCompanyListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { createCompany } from 'graphql/methods/admin/company';
import apollo from 'graphql/apollo';
import { userCompanyFragment } from 'graphql/fragments';
import { useCanCreateCompany } from 'graphql/hooks';
import equal from 'fast-deep-equal/es6/react';
import confirmModal from 'utils/confirmModal';
import { isEqual, pick } from 'lodash';
import classes from './CompanyCard.module.less';
import { CompanyExistingFormikInput, CompanyForm } from '../components/Inputs';
import { companyFormTabSchema } from '../components/schema';
import CompanyCard from './CompanyCard';
import ShoppingCartFormItem from '../../components/ShoppingCartFormItem';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const { TabPane } = Tabs;

const CreateCompanyTab = () => {
  const { data, loading } = useCanCreateCompany();
  const { allow, error } = data;
  const { t } = useTranslation();
  const isAllow = allow === true;

  if (loading) return skeletons.map((k) => <Skeleton title loading active key={k} />);
  if (error)
    return (
      <Alert
        message={
          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
          <Trans i18nKey={`BackendErrors.${error}`} components={{ a: <a href="mailto:support@kanzleipilot.de" /> }} />
        }
        type="error"
      />
    );
  if (isAllow)
    return (
      <Form layout="vertical">
        <div className={classes.createForm}>
          <CompanyForm />
        </div>
      </Form>
    );
  return <Alert message={t(`common.tenant.noAllowedToCreateCompany`)} type="info" />;
};
const CreateCompanyTabMemo = memo(CreateCompanyTab, equal);

const AddExistingCompanyTab = ({ changeTab }) => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(userCompanyListQuery, { fetchPolicy: 'cache-and-network' });
  const companies = grabFirstGQLDataResult(data);
  return (
    <div>
      <CompanyExistingFormikInput
        changeTab={changeTab}
        companies={companies}
        loading={loading}
        placeholder={t('user.ShoppingCart.ContactData.company.inputs.company.placeholder')}
      />
    </div>
  );
};
const AddExistingCompanyTabMemo = memo(AddExistingCompanyTab, equal);

const AddCompanyContent = () => {
  const { setFieldValueAndTouched } = useFormikContext();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('1');
  const changeTab = useCallback(
    (key) => {
      setActiveKey(key);
      setFieldValueAndTouched('tab', key);
    },
    [setFieldValueAndTouched],
  );

  return (
    <Tabs type="card" activeKey={activeKey} className={classes.tabs} onChange={changeTab}>
      <TabPane tab={t('user.ShoppingCart.ContactData.company.addExistingCompanyTab')} key="1">
        <AddExistingCompanyTabMemo changeTab={changeTab} />
      </TabPane>
      <TabPane tab={t('user.ShoppingCart.ContactData.company.createNewCompanyTab')} key="2">
        <CreateCompanyTabMemo />
      </TabPane>
    </Tabs>
  );
};
const CompanyContacts = ({ onOk, modal }) => {
  const handleOk = (isReplaceContacts) => {
    onOk(isReplaceContacts);
    modal.destroy();
  };
  const { t } = useTranslation();
  return (
    <div className="ant-modal-confirm-btns">
      <Button type="primary" onClick={() => handleOk(true)}>
        {t('user.ShoppingCart.ContactData.modal.replace')}
      </Button>
      <Button onClick={() => handleOk(false)}>{t('user.ShoppingCart.ContactData.modal.doNotReplace')}</Button>
      <Button onClick={modal.destroy}>{t('user.ShoppingCart.ContactData.modal.cancel')}</Button>
    </div>
  );
};

const changeCompanyContacts = ({ onOk }) => {
  const modal = confirmModal({
    closable: true,
    maskClosable: true,
    width: 800,
    title: i18n.t('user.ShoppingCart.ContactData.modal.titles.changeCompanyContacts'),
    className: classes.confirmModal,
  });
  modal.update({
    content: <CompanyContacts onOk={onOk} modal={modal} />,
  });
};
const addNewCompanyModal = ({ onChange, companyId: companyIdProp, contacts }) =>
  inputConfirmModal({
    formContent: () => <AddCompanyContent />,
    fields: [],
    onSubmitWithClose: async ({ values: { company, tab, ...newCompany }, close }) => {
      let values;
      if (tab === '1') {
        values = apollo.readFragment({
          id: `Company___${company}`,
          fragmentName: 'userCompanyFragment',
          fragment: userCompanyFragment,
        });
      } else {
        const { data } = await createCompany(newCompany);
        values = grabFirstGQLDataResult(data);
      }
      if (isEqual(values.contacts, contacts)) {
        if (companyIdProp && companyIdProp === values._id) close();
        else {
          onChange(values, false);
          close();
        }
      } else if (companyIdProp || contacts.length) {
        changeCompanyContacts({
          onOk: (isReplaceContacts) => {
            onChange(values, isReplaceContacts);
            close();
          },
        });
      } else {
        onChange(values, true);
        close();
      }
    },
    value: {
      tab: '1',
      company: companyIdProp,
      type: 'company',
      name: '',
      identifier: '',
      street: '',
      commercialObject: '',
      houseNumber: '',
      cityCode: '',
      city: '',
      country: 'DE',
    },
    headerText: i18n.t(`user.ShoppingCart.ContactData.modal.titles.${companyIdProp ? 'changeCompany' : 'addCompany'}`),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: companyFormTabSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['name', i18n.t('user.ShoppingCart.ContactData.company.duplicatedErrorMessage.name')],
    },
  });
const companyIdName = 'companyId';
const contactsName = 'contacts';

function CompanyField() {
  const { values, setValues } = useFormikContext();
  const {
    companyId,
    initializationConfigDateForCompany,
    contacts: contactsFormik,
  } = useMemo(
    () => ({
      companyId: values.companyId,
      initializationConfigDateForCompany: values.initializationConfigDateForCompany,
      contacts: values[contactsName],
    }),
    [values],
  );
  const onChange = useCallback(
    ({ _id, contacts }, isReplaceContacts) => {
      const newValues = { [companyIdName]: _id };
      if (isReplaceContacts) newValues[contactsName] = contacts.map((contact) => pick(contact, ['_id', 'email']));
      setValues((state) => ({ ...state, ...newValues }), true);
    },
    [setValues],
  );
  const addNewCompany = useCallback(
    () => addNewCompanyModal({ onChange, companyId, contacts: contactsFormik }),
    [companyId, contactsFormik, onChange],
  );
  return (
    <ShoppingCartFormItem name={companyIdName} label="" className="form-item-no-margin">
      <CompanyCard
        company={companyId}
        addNewCompany={addNewCompany}
        onChange={onChange}
        initializationConfigDateForCompany={initializationConfigDateForCompany}
      />
    </ShoppingCartFormItem>
  );
}
export default CompanyField;
